<template>
  <div class='service'>
    <div class='globle_border' v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入客服姓名" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="name" label="客服姓名"></el-table-column>
          <el-table-column prop="phone" label="客服电话"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="客服姓名" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入客服姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客服电话" prop="phone">
              <el-input v-model="ruleForm.phone" placeholder="请输入客服电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dialogVisible: false,
      loading: false,
      submitLoding: false,
      isAdd: true,
      search: '',
      tableData: [],
      ruleForm: {
        name: '',
        phone: '',
      },
      rules: {
        name: [{ required: true, message: '请输入客服姓名', trigger: 'submit' }],
        phone: [{ required: true, message: '请输入客服电话', trigger: 'submit' }],
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created () {
    this.getServiceList()
  },
  methods: {
    // 客服列表
    getServiceList () {
      this.loading = true
      this.$axios.get(this.$api.customerList, {
        params: {
          name: this.search,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    // 搜索
    onSearch () {
      this.currentPage = 1
      this.getServiceList()
    },
    // 新增（对话框）
    handleAdd () {
      this.isAdd = true
      this.dialogVisible = true
    },
    // 修改（对话框）
    handleEdit (index, row) {
      this.isAdd = false;
      this.$axios.get(this.$api.customerQuery, {
        params: {
          id: row.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result;
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      }).catch(() => {
        this.dialogVisible = false;
      });
    },
    // 删除
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除该客服人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(this.$api.customerDelete, {
          id: row.id
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
          }
          this.getServiceList()
        })
      }).catch(() => { });
    },
    // 提交表单
    submitForm (formName) {
      let param = {
        id: this.ruleForm.id,
        name: this.ruleForm.name,
        phone: this.ruleForm.phone,
      }
      this.submitLoding = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.$axios.post(this.$api.customerAdd, param).then((res) => {
              if (res.data.code == 100) {
                this.$message.success('添加成功');
              }
              this.getServiceList();
              this.submitLoding = false
              this.dialogVisible = false;
              this.resetForm();
            });
          } else {
            this.$axios.put(this.$api.customerPut, param).then((res) => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc);
              }
              this.getServiceList();
              this.submitLoding = false
              this.dialogVisible = false;
              this.resetForm();
            }).catch((err) => {
              this.$message.error(err.data.desc);
            });
          }
        } else {
          this.submitLoding = false
          this.$message.error('提交失败');
          return false;
        }
      });
    },
    // 关闭对话框
    onCancel () {
      this.resetForm()
      this.dialogVisible = false
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e
      this.getServiceList()
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e
      this.getServiceList()
    },
    // 重置表单
    resetForm () {
      this.ruleForm = {
        name: this.ruleForm.name,
        phone: this.ruleForm.phone,
      }
      this.$refs.ruleForm.resetFields();
    },
  },
}
</script>

<style scoped lang='scss'>
.service {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
